<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseInventor`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ title }}</a> <span class="badge bg-light-success">{{ inventors ? inventors.length : 0 }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content">
      <b-collapse
        :id="`collapseInventor`"
      >
        <div class="card-content">
          <div class="card-header d-flex align-items-center justify-content-between">
            <div>
              <h4><strong>{{ inventors ? inventors.length : 0 }}</strong> inventors</h4>
            </div>
          </div>
          <div class="table-responsive-sm">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th class="text-center">
                    Owner</th>
                  <th>Name</th>
                  <th>ICREA member</th>
                  <th>Institution</th>
                  <th>Country</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="inventor, index in inventors"
                  :key="`inventor-${index}`"
                >
                  <td
                    v-if="inventor.user_id == ownerId"
                    class="text-center"
                  >
                    <span><i data-feather="star" /></span>
                  </td>
                  <td
                    v-else
                  />
                  <td>{{ inventor.name }}</td>
                  <td><span
                    class="badge"
                    :class="`bg-light-${inventor.user_id ? 'success' : 'danger'}`"
                  >{{ inventor.user_id ? 'Yes' : 'No' }}</span></td>
                  <td>{{ inventor.host_name ? inventor.host_name : inventor.user_host }}</td>
                  <td>{{ inventor.country ? inventor.country : '---' }}</td>
                  <td class="text-end">
                    <a
                      v-if="inventor.user_id"
                      class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                      :href="$router.resolve({name: 'researcher.dashboard', params: { id: inventor.user_id }, query: { actAs: $route.query.actAs }}).href"
                      target="_blank"
                    ><i data-feather="user" /></a>
                    <a
                      class="btn btn-icon btn-icon rounded-circle btn-flat-danger"
                      @click="deleteInventor(index)"
                    ><i data-feather="trash-2" /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-body">
            <a
              v-b-toggle="`collapseInventorAdd`"
              class="btn btn-sm btn-dark dis-ib"
            ><i data-feather="plus" /> Add inventor</a>

            <b-collapse
              :id="`collapseInventorAdd`"
              class="collapse mt-3"
            >
              <div class="card shadow-none bg-transparent border-secondary">
                <div class="card-body">
                  <h3>Add inventor</h3>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label d-block"
                    > {{ manualUser ? labels['inventors.name_not_icrea'] : labels['inventors.select_icrea'] }} <a
                      class="float-end text-danger"
                      @click="toggleManualUser"
                    ><i data-feather="alert-triangle" /> <u>Click here</u> if the inventor is not in this list</a></label>
                    <v-select
                      v-if="!manualUser"
                      v-model="selectedInventor"
                      label="name"
                      :options="users"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event } , 'users/fetchFakeUsers')"
                    />
                    <input
                      v-else
                      v-model="selectedInventor"
                      type="text"
                      class="form-control"
                      placeholder="Write the name of the inventor"
                    >
                  </div>
                  <div
                    v-if="selectedInventor || manualUser"
                    class="mb-1"
                  >
                    <label
                      for=""
                      class="form-label d-block"
                    >{{ labels['inventors.search_institution'] }} <a
                      class="float-end text-danger"
                      @click="toggleInstitution"
                    ><i data-feather="alert-triangle" /> <u>Click here</u> if the institution is not in this list</a></label>
                    <v-select
                      v-if="!manualInstitution && selectedInventor && selectedInventor.id"
                      v-model="selectedHost"
                      label="name"
                      :options="selectedInventor.hosts"
                      :get-option-key="option => option.id"
                    />
                    <v-select
                      v-else-if="!manualInstitution && (!selectedInventor || !selectedInventor.id)"
                      v-model="selectedHost"
                      label="name"
                      :options="institutions"
                      :get-option-key="option => option.id"
                    />
                    <input
                      v-else
                      v-model="selectedHost"
                      type="text"
                      class="form-control"
                      placeholder="Write your institution"
                    >
                  </div>
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >{{ labels['inventors.country'] }}</label>
                    <input
                      v-model="selectedCountry"
                      type="text"
                      class="form-control"
                      :disabled="countryFromHost"
                    >
                  </div>
                  <div class="mb-2">
                    <a
                      class="btn btn-sm btn-outline-success me-1"
                      @click="createInventor"
                    >Create inventor</a>
                    <a
                      v-b-toggle="`collapseInventorAdd`"
                      class="btn btn-sm btn-flat-danger"
                    >Cancel</a>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BCollapse,
    vSelect,
  },
  props: {
    title: { type: String, required: true },
    inventors: { type: Array, required: false, default: () => [] },
    owner: { type: Object, required: false, default: null },
  },
  data() {
    return {
      manualUser: false,
      selectedInventor: null,
      manualInstitution: false,
      selectedHost: null,
      selectedCountry: null,
      countryFromHost: false,
      ownerId: null,
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/fakeUsers',
      institutions: 'institutions/institutions',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/itemLabels',
    }),
  },
  watch: {
    selectedHost() {
      if (this.selectedHost) {
        if (this.selectedHost.country) {
          this.selectedCountry = this.selectedHost.country
          this.countryFromHost = true
        } else {
          this.selectedCountry = null
          this.countryFromHost = false
        }
      }
    },
    owner() {
      if (this.owner && this.inventors.length === 0 && this.loggedUser.roles.includes('super-admin')) {
        this.inventors.push({
          name: this.owner.full_name,
          user_id: this.owner.id,
          host_name: this.owner.current_host ? this.owner.current_host.name : null,
          host_id: this.owner.current_host ? this.owner.current_host.id : null,
          country: this.owner.current_host ? this.owner.current_host.country : null,
        })
        this.ownerId = this.owner.id
      } else if (this.owner && this.loggedUser.roles.includes('super-admin')) {
        if (this.inventors.map(e => e.user_id).indexOf(this.ownerId) !== -1) {
          this.inventors.splice(this.inventors.map(e => e.user_id).indexOf(this.ownerId), 1)
          this.inventors.push({
            name: this.owner.full_name,
            user_id: this.owner.id,
            host_name: this.owner.current_host ? this.owner.current_host.name : null,
            host_id: this.owner.current_host ? this.owner.current_host.id : null,
            country: this.owner.current_host ? this.owner.current_host.country : null,
          })
          this.ownerId = this.owner.id
        }
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
  async mounted() {
    await this.$store.dispatch('institutions/fetch')
    if (this.inventors.length === 0 && !this.loggedUser.roles.includes('super-admin')) {
      this.ownerId = this.loggedUser.id
      this.inventors.push({
        name: this.loggedUser.full_name,
        user_id: this.loggedUser.id,
        host_name: this.loggedUser.hosts.length > 0 ? this.loggedUser.hosts[0].name : null,
        host_id: this.loggedUser.hosts.length > 0 ? this.loggedUser.hosts[0].id : null,
        country: this.loggedUser.hosts.length > 0 ? this.loggedUser.hosts[0].country : null,
      })
    } else if (this.owner) {
      this.ownerId = this.owner.id
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async onSearch(search, store) {
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    toggleManualUser() {
      this.manualUser = !this.manualUser
      this.selectedInventor = null
    },
    toggleInstitution() {
      this.manualInstitution = !this.manualInstitution
      this.selectedHost = null
    },
    createInventor() {
      if (this.selectedInventor && this.selectedHost) {
        this.inventors.push({
          name: this.selectedInventor.id ? this.selectedInventor.name : this.selectedInventor,
          user_id: this.selectedInventor.id,
          host_name: this.selectedHost.id ? this.selectedHost.name : this.selectedHost,
          host_id: this.selectedHost.id,
          country: this.selectedCountry,
        })
        feather.replace({
          width: 14,
          height: 14,
        })
        this.selectedInventor = null
        this.selectedHost = null
        this.selectedCountry = null
        this.countryFromHost = false
      }
    },
    deleteInventor(index) {
      this.inventors.splice(index, 1)
    },
  },
}
</script>
