<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ property.id ? 'Edit' : 'New' }} industrial property<br>
                <!-- <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      v-model="property.publish"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    >
                      <small class="ms-1">
                        Publish
                      </small>
                    </label>
                  </div>
                </div> -->
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to industrial property list"
              @click="$router.push({ name: 'researcher.industrial-property.index' })"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="user.roles.includes('super-admin')"
              class="btn btn-icon btn-flat-secondary"
              @click="$store.dispatch('modals/toggleRequestModal', 'IndustrialProperty')"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <SearchResearcher :item="property" />

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6 mb-1">
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['content.type'] }}</label>
                      <v-select
                        v-model="property.type"
                        label="name"
                        :options="types"
                        :get-option-key="option => option.id"
                        @input="changeType($event)"
                      />
                    </div>
                    <div
                      v-if="statuses && statuses.length > 0"
                      class="col-6 mb-1"
                    >
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['content.status'] }}</label>
                      <v-select
                        v-model="property.status"
                        label="status"
                        :options="statuses"
                        :get-option-key="option => option.id"
                      />
                    </div>
                    <div
                      class="mb-1"
                      :class="{'col-12': statuses && statuses.length > 0, 'col-6': !statuses || !statuses.length > 0}"
                    >
                      <label
                        for=""
                        class="form-label"
                      >{{ property.type ? `Name of the ${property.type.name}` : labels['content.title'] }}</label>
                      <input
                        v-model="property.title"
                        type="text"
                        class="form-control"
                      >
                    </div>
                    <div
                      v-if="property.type && property.type && property.type.patent_app_number"
                      class="mb-1"
                    >
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['content.patent_number'] }}</label>
                      <input
                        v-model="property.patent_application_number"
                        type="text"
                        class="form-control"
                        @change="searchPatent()"
                      >
                    </div>
                    <div
                      v-if="alert"
                      class="mb-1 alert alert-danger p-1"
                    >
                      {{ labels['content.patent_number_alert'] }}
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      > {{ labels['content.summary'] }}</label>
                      <quill-editor v-model="property.summary" />
                    </div>

                  </div>
                  <hr>
                  <h4>Dates</h4>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        > {{ labels['content.communicate_date_to_icrea'] }}</label><br>
                        <date-picker
                          v-model="property.communication_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div
                      v-if="property.type && property.type && property.type.filing_date"
                      class="col-sm-3"
                    >
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        > {{ labels['content.filling_date'] }}</label><br>
                        <date-picker
                          v-model="property.filing_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div
                      v-if="property.type && property.type && property.type.contract_date"
                      class="col-sm-3"
                    >
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        > {{ labels['content.contract_date'] }}</label><br>
                        <date-picker
                          v-model="property.contract_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div
                      v-if="property.type && property.type && property.type.licensed_date"
                      class="col-sm-3"
                    >
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        > {{ labels['content.licensed_date'] }}</label><br>
                        <date-picker
                          v-model="property.licensed_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div
                      v-if="property.type && property.type && property.type.sold_date"
                      class="col-sm-3"
                    >
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        > {{ labels['content.sold_date'] }}</label><br>
                        <date-picker
                          v-model="property.sold_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div
                      v-if="property.type && property.type && property.type.abandoned_date"
                      class="col-sm-3"
                    >
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        > {{ labels['content.abandoned_date'] }}</label><br>
                        <date-picker
                          v-model="property.abandoned_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div
                      v-if="property.type && property.type && property.type.publication_date"
                      class="col-sm-3"
                    >
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        > {{ labels['content.publication_date'] }}</label><br>
                        <date-picker
                          v-model="property.publication_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="user.roles.includes('super-admin')"
              class="card"
            >
              <div
                v-b-toggle="`collapseIcreaComments`"
                class="card-header"
              >
                <h3 class="cart-title">
                  <a data-action="collapse">Admin comments</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <b-collapse
                :id="`collapseIcreaComments`"
              >
                <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <div class="mb-1">
                          <label
                            for=""
                            class="form-label"
                          >Admin comments</label>
                          <quill-editor v-model="property.admin_comments" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <Inventor
              :title="'Inventors'"
              :inventors="property.inventors"
              :owner="property.user"
              @saveAnswer="saveFiles($event)"
            />

            <AddFile
              :id="1"
              :title="'Documents'"
              :type="3"
              :files="property.files"
              @saveAnswer="saveFiles($event)"
            />

            <AttachedForm
              :type="'IndustrialProperty'"
              :model-id="propertyId"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!loading"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> <span class="ms-25 align-middle"> Save and Exit</span>
          </button>
          <button
            v-if="loading"
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import vSelect from 'vue-select'
import AddFile from '../../admin/senior-call/components/AddFile.vue'
import Inventor from './components/Inventor.vue'
import SearchResearcher from '../../partials/components/SearchResearcher.vue'
import 'vue2-datepicker/index.css'
import AttachedForm from '../../forms/partials/AttachedForm.vue'

export default {
  components: {
    vSelect,
    AddFile,
    DatePicker,
    Inventor,
    SearchResearcher,
    AttachedForm,
  },
  data() {
    return {
      propertyId: this.$route.params.id,
      loading: false,
      selectedInstitution: {},
      manualInstitution: false,
      alert: false,
    }
  },
  computed: {
    ...mapGetters({
      property: 'tecnologyTransfers/industrialProperty',
      institutions: 'institutions/institutions',
      statuses: 'status/statuses',
      types: 'industrialPropertyParentTypes/items',
      users: 'users/fakeUsers',
      user: 'auth/admin', // Logged user
      labels: 'sectionLabels/itemLabels',
      attached: 'requestForms/attached',
    }),
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'technology_transfer.industrial_property')
    if (this.propertyId) {
      await this.$store.dispatch('tecnologyTransfers/fetchIndustrialProperty', this.propertyId)
      if (this.property.institution_manual) {
        this.manualInstitution = true
      }

      if (this.property.type) {
        this.$store.dispatch('status/filterStatus', this.property.type.name)
      }
    } else {
      this.$store.dispatch('tecnologyTransfers/clearIndustrialProperty')
    }

    this.$store.dispatch('industrialPropertyParentTypes/fetch')

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async searchPatent() {
      const { data } = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/tecnology-transfers/industrial-properties/check-patent-number`, {
        patent_number: this.property.patent_application_number,
      })

      this.alert = data
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    toggleInstitution() {
      this.manualInstitution = !this.manualInstitution
      this.property.host = null
      this.property.institution_manual = null
    },
    async save() {
      if (this.alert) {
        Vue.swal('This patent number is already registered, please contact with the administrator.', '', 'warning')
        return
      }

      this.loading = true
      this.property.attached_form = this.attached

      if (this.propertyId) {
        await this.$store.dispatch('tecnologyTransfers/updateIndustrialProperty', { id: this.propertyId, data: this.property })
      } else {
        await this.$store.dispatch('tecnologyTransfers/createIndustrialProperty', this.property)
      }
      this.loading = false
      Vue.swal('Property updated successfully!', '', 'success').then(() => {
        this.$router.push({ name: 'researcher.industrial-property.index' })
      })
    },
    saveFiles(files) {
      this.property.files_new = files.data
    },
    changeType(data) {
      this.alert = false
      this.property.status = null
      this.$store.dispatch('status/filterStatus', data.name)
      // if (data.name === 'Patents') {
      //   this.$store.dispatch('status/filterStatus', data.name)
      // } else {
      //   this.$store.dispatch('status/filterStatus', 'Industrial property')
      // }
    },
  },
}
</script>
